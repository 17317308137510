interface SliderElementProps {
  bgImage: string;
  title: string;
  number: number;
  activeIndex: number;
  onClick: (e: number) => void;
}
export const SliderElement = ({
  bgImage,
  number,
  title,
  activeIndex = 0,
  onClick,
}: SliderElementProps) => {
  return (
    <div
      onClick={() => onClick(number - 1)}
      className={`aspect-[3/4] slider-element border-b-8 bg-center  border-solid box-content ${activeIndex + 1 === number && 'border-primary'} text-white ${activeIndex + 1 !== number && 'bg-gray-700'} bg-blend-overlay`}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: `${activeIndex + 1 === number ? '110%' : '100%'}`,
        transition: 'all 1000ms',
      }}
    >
      <div className={'flex flex-col justify-between h-full'}>
        <div
          className={'w-full p-8 font-bold text-4xl text-right font-shoulders'}
        >
          {number}
        </div>
        <div
          className={
            'w-full p-8 font-bold text-4xl  lg:text-2xl xl:text-4xl text-left  h-32 md:h-48'
          }
        >
          {title}
        </div>
      </div>
    </div>
  );
};
