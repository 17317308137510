import './style.scss';

import { FC, ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}
const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  const openLinkInNewTab = () => {
    const newTab = window.open('https://www.ga-solutions.me/', '_blank'); // Replace with your URL
    if (newTab) newTab.focus(); // Focus on the new tab if it opened successfully
  };
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          openLinkInNewTab();
        }}
      >
        <div
          className="absolute right-1 text-3xl top-1 h-4 w-4 flex justify-center items-center bg-white p-1 rounded-2xl"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          ×
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
