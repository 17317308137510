import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from '../components/footer/footer.tsx';
import Modal from '../components/modal/Modal.tsx';
import Popup from './../assets/images/popup/pergola.jpg';
import Navbar from './menu/Navbar.tsx';

const variants = {
  enter: { opacity: 0, x: '100%' },
  center: { opacity: 1, x: 0 },
  exit: { opacity: 1, x: '-100' },
};
const modalValidDate = 1735806941000;
export const Page = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <AnimatePresence presenceAffectsLayout mode={'sync'}>
        <motion.div
          key={pathname}
          variants={variants}
          initial={{ x: '100%', opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          <Outlet />
        </motion.div>
      </AnimatePresence>
      {Date.now() < modalValidDate && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <img
            src={Popup}
            alt={'pergola'}
            width={'100%'}
            height={'100%'}
            className={'object-fill'}
            style={{
              objectFit: 'contain',
            }}
          />
        </Modal>
      )}
      <Footer />
    </>
  );
};
