import { LuConstruction } from 'react-icons/lu';

const WorkInProgress = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-gray-200 text-primary">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-primary mb-4 text-center">
          Work in Progress
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          This page is currently under construction. Please check back later!
        </p>
        <LuConstruction size={64} className={'mx-auto'} />
      </div>
    </div>
  );
};

export default WorkInProgress;
