import { Textarea } from '@mantine/core';
import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';

import { ContactUsSchemaType } from './schema';

interface ControlledTextAreaProps {
  control: Control<ContactUsSchemaType>;
  name: Path<ContactUsSchemaType>;
  placeholder: string;
}

const ControlledTextArea: React.FC<ControlledTextAreaProps> = (props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <Textarea
          {...field}
          error={fieldState.error?.message}
          size="lg"
          placeholder={props.placeholder}
        />
      )}
    />
  );
};

export default ControlledTextArea;
