import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { FaEnvelope, FaFacebook, FaInstagramSquare } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import Logo from '../../assets/images/Logo_main.svg';
import { LocalStorage } from '../../hooks/localStorage.ts';
import { MainRoutes } from '../../model/enums/routes/MainRoutes.ts';
import { social } from '../../variables/social.ts';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const handleNav = () => {
    setNav(!nav);
  };
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  useEffect(() => {
    const storedLanguage = LocalStorage.getItem('lang');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (language: string) => {
    LocalStorage.setItem('lang', language);
    i18n.changeLanguage(language);
  };

  const navItems = [
    { id: 1, text: t('navigation.home'), route: '/' },
    { id: 4, text: t('navigation.services'), route: MainRoutes.SERVICES },
    { id: 5, text: t('navigation.offshoring'), route: MainRoutes.OFFSHORING },
    { id: 2, text: t('navigation.quality'), route: MainRoutes.QUALITY },
    { id: 7, text: t('navigation.locations'), route: MainRoutes.LOCATIONS },
    { id: 6, text: t('navigation.contact_us'), route: MainRoutes.CONTACT },
    { id: 6, text: t('navigation.career'), route: MainRoutes.CAREER },
  ];
  return (
    <div
      className="flex bg-white fixed  lg:bg-gradient lg:bg-transparent p-4 lg:block justify-between items-center w-full mx-auto text-white z-30 md:p-4 lg:absolute top-0"
      style={{
        boxShadow: '10px 10px 34px 0px rgba(0,0,0,0.4)',
      }}
    >
      <div
        className={
          'hidden w-full lg:flex border-b border-slate-600 border-solid'
        }
      >
        <a
          href={'https://maps.app.goo.gl/7eUvKLQ2p8mUp8w98'}
          target={'_blank'}
          className={
            'mr-4 p-4 border-r border-slate-600 border-solid flex items-center'
          }
          rel="noreferrer"
        >
          <MdLocationPin className={'text-primary text-xl'} />
          Industrijska bb, 84000 Bijelo Polje, Montenegro
        </a>
        <div className={'flex-1 p-4 flex justify-between'}>
          <a
            href={'mailto:office@gi-solutions.me'}
            target={'_blank'}
            className={'mr-4 p-4 flex items-center gap-2'}
            rel="noreferrer"
          >
            <div>
              <FaEnvelope className={'text-primary text-xl '} />
            </div>
            <div>office@gi-solutions.me</div>
          </a>
          <div className={'flex gap-2'}>
            <div className={'justify-end text-3xl flex gap-4 text-primary'}>
              <a href={social.facebook} target={'_blank'} rel="noreferrer">
                <FaFacebook className={'cursor-pointer'} />
              </a>
              <a href={social.instagram} target={'_blank'} rel="noreferrer">
                <FaInstagramSquare className={'cursor-pointer'} />
              </a>
            </div>
            <div className={'text-xl'}>
              <span
                className={`cursor-pointer ${i18n.language === 'me' && 'text-primary'}`}
                onClick={() => changeLanguage('me')}
              >
                Mne
              </span>{' '}
              /{' '}
              <span
                className={`cursor-pointer ${i18n.language === 'en' && 'text-primary'}`}
                onClick={() => changeLanguage('en')}
              >
                Eng
              </span>{' '}
              /{' '}
              <span
                className={`cursor-pointer ${i18n.language === 'de' && 'text-primary'}`}
                onClick={() => changeLanguage('de')}
              >
                Ger
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Logo */}
      <div className={'flex p-4 items-center justify-between'}>
        <img
          loading={'lazy'}
          src={Logo}
          alt={'logo'}
          height={60}
          className={'cursor-pointer'}
          onClick={() => navigate('/')}
        />

        {/* Desktop Navigation */}
        <ul className="hidden lg:flex">
          {navItems.map((item) => (
            <li
              key={item.id}
              className="whitespace-nowrap hover:bg-primary font-extrabold rounded m-2 cursor-pointer duration-300 hover:text-white text-center"
            >
              <NavLink
                to={item.route}
                className={({ isActive }) =>
                  isActive
                    ? 'border-primary border-b-2 border-solid p-4'
                    : 'p-4'
                }
              >
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div onClick={handleNav} className="block lg:hidden z-40 text-primary">
        {nav ? <AiOutlineClose size={48} /> : <AiOutlineMenu size={48} />}
      </div>
      <div
        className={
          nav
            ? `services-background-image-transparent h-screen bg-contain bg-bottom bg-no-repeat p-4 items-top justify-between flex fixed lg:hidden left-0 top-[120px] w-full z-30 border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500`
            : 'flex ease-in-out w-full h-[calc(100vh - 120px)] duration-500 fixed top-[120px] bottom-0 left-[-100%] z-30'
        }
      >
        <ul className={'p-4 text-xl font-bold'}>
          <li
            onClick={() => changeLanguage('en')}
            className={`cursor-pointer ${i18n.language === 'en' && 'text-primary'}`}
          >
            Eng
          </li>
          <li
            onClick={() => changeLanguage('me')}
            className={`cursor-pointer ${i18n.language === 'me' && 'text-primary'}`}
          >
            Mne
          </li>
          <li
            onClick={() => changeLanguage('de')}
            className={`cursor-pointer ${i18n.language === 'de' && 'text-primary'}`}
          >
            Ger
          </li>
        </ul>
        <div>
          <ul>
            {navItems.map((item) => (
              <li
                key={item.id}
                className={`p-4 ${lang === 'de' ? 'text-lg' : 'text-3xl'}  text-right cursor-pointer duration-300 font-extrabold hover:text-primary border-gray-600`}
              >
                <Link to={item.route} onClick={() => setNav(false)}>
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
          <div className={'justify-end text-5xl flex gap-4 text-primary'}>
            <a href={social.facebook} target={'_blank'} rel="noreferrer">
              <FaFacebook />
            </a>
            <a href={social.instagram} target={'_blank'} rel="noreferrer">
              <FaInstagramSquare />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
