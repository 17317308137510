import 'animate.css';

import { useTranslation } from 'react-i18next';

import { AutomationImage } from '../../assets/images/automation';
import { Hero } from '../../components/hero/Hero.tsx';
import { LaboratoryServices } from './LaboratoryServices.tsx';
import { Services } from './Services.tsx';
import { Tiles } from './Tiles.tsx';

export const Automation = () => {
  const { t } = useTranslation();
  return (
    <>
      <Hero
        image={AutomationImage}
        text={
          <div className={'overflow-hidden'}>
            <h2
              className={
                'text-primary text-5xl md:text-6xl font-bold leading-12 font-NouvelBold animate__animated animate__slideInUp'
              }
            >
              {t('automation.hero.titlePart1')}
            </h2>
            <h2
              className={
                'text-5xl md:text-6xl font-bold text-white leading-12 font-NouvelBold animate__animated animate__slideInDown'
              }
            >
              {t('automation.hero.titlePart2')}
            </h2>
          </div>
        }
      />
      <Tiles />
      <LaboratoryServices />
      <Services />
    </>
  );
};
