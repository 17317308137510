import { useTranslation } from 'react-i18next';

import offshoringMainMobile from '/src/assets/images/offshoring/offshoring-main-mobile.png';
import offshoringImage from '/src/assets/images/offshoring/offshorting-main.webp';

import MainSection from '../../../components/main-section/main-section';

const LandingSection = () => {
  const { t } = useTranslation();
  return (
    <MainSection
      title={{
        primary: t('offshoring.landing.titlePart1'),
        secondary: t('offshoring.landing.titlePart2'),
      }}
      imageSource={{
        desktop: offshoringImage,
        mobile: offshoringMainMobile,
      }}
    />
  );
};

export default LandingSection;
