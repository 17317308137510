import 'animate.css';

import { Fade, Slide } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

import { Policy } from '../../../assets/docs';
import { ArrowDownFilled } from '../../../assets/images/home-page';
import { Meeting1, TopRightArrowFill } from '../../../assets/images/quality';
import useIsMobileQuery from '../../../hooks/use-mobile-query';

const BsuinessPolicySection = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobileQuery();
  const lang = i18n.language;
  return (
    <div className="font-[NouvelRRegular] relative">
      <div className={`flex flex-col md:flex-row-reverse bg-[#EEEEEE]`}>
        <div
          className={`flex-1 mx-[30px] mt-10 md:mt-20 flex flex-col gap-10 md:text-right md:mr-[100px] md:ml-[8vw]`}
        >
          <div
            className={`font-bold ${lang === 'de' ? 'text-3xl' : 'text-6xl'} md:text-[64px] flex flex-col leading-[55px]`}
          >
            <Slide>
              <span className="text-primary">
                {t('quality.policy.titlePart1')}
              </span>
              <span>{t('quality.policy.titlePart2')}</span>
            </Slide>
          </div>
          <div className={`w-[110px] h-[2px] bg-primary self-end`} />
          <Slide direction={'right'}>
            <h3 className={`font-bold text-3xl md:text-4xl`}>
              {t('quality.policy.subtitle')}
            </h3>
          </Slide>
          <Slide cascade>
            <div className={`text-[21px] md:text-[28px] mb-10 md:mb-40`}>
              {t('quality.policy.long')}
            </div>
          </Slide>
        </div>
        <img
          className="w-full md:w-1/2 object-contain object-center"
          src={Meeting1}
          alt={'meeting'}
        />
      </div>
      <div className="relative bottom-0 -translate-y-24 md:-translate-y-1/5 flex items-end">
        {!isMobile && (
          <img src={TopRightArrowFill} className="ml-auto" alt={'top'} />
        )}
        <div className=" overflow-hidden mx-6 px-[22px] pt-6 md:pt-10 md:px-[83px] flex flex-col bg-black/75 text-white backdrop-blur-lg gap-6 md:w-1/2 md:ml-auto md:mr-[100px]">
          <div className={`text-[30px] md:text-[36px] font-bold`}>
            <Fade
              cascade
              duration={100}
              className={`text-[30px] md:text-[36px] font-bold text-wrap`}
              style={{ whiteSpace: 'wrap' }}
            >
              <p>{t('quality.policy.policies')}</p>
            </Fade>
          </div>
          <Slide cascade duration={100}>
            <ul className={`list-disc list-outside text-lg md:text-xl`}>
              <li className="ml-6">{t('quality.policy.policy1')}</li>
              <li className="ml-6">{t('quality.policy.policy2')}</li>
              <li className="ml-6">{t('quality.policy.policy3')}</li>
              <li className="ml-6">{t('quality.policy.policy4')}</li>
              <li className="ml-6">{t('quality.policy.policy5')}</li>
              <li className="ml-6">{t('quality.policy.policy6')}</li>
              <li className="ml-6">{t('quality.policy.policy7')}</li>
              <li className="ml-6">{t('quality.policy.policy8')}</li>
              <li className="ml-6">{t('quality.policy.policy9')}</li>
            </ul>
          </Slide>
          <a
            href={Policy}
            download={'policy.pdf'}
            className="flex flex-col-reverse md:flex-row gap-2 md:gap-6 items-center text-center mt-16 md:justify-end mb-14 md:mb-8"
          >
            <span className="text-[28px] font-bold">
              {t('quality.policy.download')}
            </span>
            <img
              src={ArrowDownFilled}
              width={isMobile ? 80 : 100}
              height={isMobile ? 80 : 100}
              alt={'down'}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BsuinessPolicySection;
