import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import arrowRight from '/src/assets/images/home-page/arrow-right-filled.svg';

import useIsMobileQuery from '../../../hooks/use-mobile-query';
import ControlledInput from './controlled-input';
import ControlledTextArea from './controlled-textarea';
import useContactUsSchema, { ContactUsSchemaType } from './schema';

const FormSection = () => {
  const isMobile = useIsMobileQuery();
  const contactUsSchema = useContactUsSchema();

  const { control, handleSubmit, reset } = useForm<ContactUsSchemaType>({
    defaultValues: {
      email: '',
      message: '',
      name: '',
      phone: '',
      subject: '',
    },
    resolver: zodResolver(contactUsSchema),
  });
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<ContactUsSchemaType> = (values) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('subject', values.subject);
    urlSearchParams.append(
      'body',
      `Message: ${values.message}\nPhone: ${values.phone}\nEmail: ${values.email}`,
    );
    const email = 'office@gi-solutions.me';
    window.location.href = `mailto:${email}?` + urlSearchParams.toString();
    reset();
  };

  return (
    <div className="md:w-1/2 mx-[30px] flex flex-col gap-6 font-NouvelBold my-10 md:mx-[100px] md:mt-[100px]">
      <form className="flex flex-col gap-7" onSubmit={handleSubmit(onSubmit)}>
        <ControlledInput
          control={control}
          name="name"
          placeholder={t('contactUs.form.name')}
        />
        <ControlledInput
          control={control}
          name="phone"
          placeholder={t('contactUs.form.phone')}
        />
        <ControlledInput
          control={control}
          name="email"
          placeholder={t('contactUs.form.email')}
        />
        <ControlledInput
          control={control}
          name="subject"
          placeholder={t('contactUs.form.subject')}
        />
        <ControlledTextArea
          control={control}
          name="message"
          placeholder={t('contactUs.form.message')}
        />
        <div className="flex gap-6 justify-center items-center">
          <span className="text-xl">{t('contactUs.form.send')}</span>
          <button type="submit">
            <img
              loading={'lazy'}
              alt={'right'}
              src={arrowRight}
              width={isMobile ? 80 : 100}
              height={isMobile ? 80 : 100}
            />
          </button>
        </div>
      </form>
      <button
        className={'bg-primary p-2 text-white rounded'}
        onClick={() =>
          window.open(
            'https://docs.google.com/forms/d/1YyE7Fd6s3kZOwYnHynNlMlQUS8IvAaUuLE2SDxTBwn0/viewform?edit_requested=true',
            '_blank',
          )
        }
      >
        {t('contactUs.form.fileComplaint')}
      </button>
    </div>
  );
};

export default FormSection;
