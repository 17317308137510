import ExpertiseAndSupportSection from './expertise-and-support-section/expertise-and-support-section';
import LandingSection from './landing-section/landing-section';
import OurServicesSection from './our-services-section.tsx/our-service-section';

export const Offshoring = () => {
  return (
    <>
      <LandingSection />
      <OurServicesSection />
      <ExpertiseAndSupportSection />
    </>
  );
};
