import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonRight, LogoFooter } from '../../assets/images';
import useIsMobileQuery from '../../hooks/use-mobile-query';
import { MainRoutes } from '../../model/enums/routes/MainRoutes.ts';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobileQuery();
  const lang = i18n.language;

  return (
    <div className="font-NouvelBold text-black">
      <div className="px-7 py-12 bg-[#D9D9D9] flex flex-col gap-6 lg:flex-row md:justify-around">
        <div className="flex">
          <img
            src={LogoFooter}
            loading={'lazy'}
            width={isMobile ? 75 : 113}
            height={isMobile ? 75 : 134}
            alt={'logo'}
          />
          <span
            className={`${lang === 'de' ? 'text-base' : 'text-lg'}lg:text-xl font-[ModernWarfare] font-extrabold`}
          >
            <div>{t('footer.logoTitlePart1')}</div>
            <div className="flex items-center justify-start">
              <div>
                <div>{t('footer.logoTitlePart2')}</div>
                <div>{t('footer.logoTitlePart3')}</div>
              </div>
              <img
                src={ButtonRight}
                loading={'lazy'}
                width={50}
                height={50}
                alt={'logo'}
              />
            </div>
            <div>{t('footer.logoTitlePart4')}</div>
          </span>
        </div>
        <div className="text-[21px] flex flex-col">
          <span>Global Industrial Solutions</span>
          <span>Industrijska bb, 84000</span>
          <span>Bijelo Polje, Montenegro </span>
          <span>office@gi-solutions.me</span>
        </div>

        <div className="text-[21px] flex flex-col">
          <Link to={`../${MainRoutes.SERVICES}`}>{t('footer.services')}</Link>
          <Link to={`../${MainRoutes.QUALITY}`}>
            {t('footer.certificates')}
          </Link>
          <Link to={`../${MainRoutes.LOCATIONS}`}>{t('footer.locations')}</Link>
        </div>

        <div className="text-[21px] flex flex-col">
          <a
            href={'https://www.facebook.com/globalindustrialsolutionsdoo/'}
            target={'_blank'}
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href={'https://www.instagram.com/global.industrial.solutions/'}
            target={'_blank'}
            rel="noreferrer"
          >
            Instagram
          </a>
        </div>
      </div>
      <div className="bg-black text-white text-xl p-4 text-center">
        © Copyright Global Industrial Solutions
      </div>
    </div>
  );
};

export default Footer;
