import { useTranslation } from 'react-i18next';

import { ServicesMain } from '../../assets/images/services';
import { Hero } from '../../components/hero/Hero.tsx';
import { CustomSolutions } from './CustomSolutions.tsx';
import { Gallery } from './Gallery.tsx';
import { Message } from './Message.tsx';
import { Tiles } from './Tiles.tsx';

export const Services = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <>
      <Hero
        image={ServicesMain}
        text={
          <div>
            <h2
              className={`text-primary ${lang === 'de' ? 'text-3xl' : 'text-6xl'} lg:text-6xl font-bold leading-12 font-NouvelBold  animate__animated animate__slideInUp`}
            >
              {t('services.hero.titlePart1')}
            </h2>
            <h2
              className={`${lang === 'de' ? 'text-3xl' : 'text-6xl'} lg:text-6xl font-bold text-white leading-12 font-NouvelBold  animate__animated animate__slideInDown`}
            >
              {t('services.hero.titlePart2')}
            </h2>
          </div>
        }
      />
      <Tiles />
      <CustomSolutions />
      <Gallery />
      <Message />
    </>
  );
};
