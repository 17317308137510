import { Slide, Zoom } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

import { Laboratory } from '../../assets/images/automation';
import { Line } from '../../assets/images/services';

export const LaboratoryServices = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className={`relative flex md:items-end flex-col bg-white md:flex-row-reverse md:bg-[url('${Laboratory}')]`}
      >
        <div className={'flex-1 h-screen'}>
          <img
            loading={'lazy'}
            src={Laboratory}
            alt={'laboratory'}
            className={'h-full w-full object-cover'}
          />
        </div>
        <div className={'flex-1 md:flex-grow-0  z-20 right-1/2 bottom-0'}>
          <div
            className={
              'p-4 md:p-0 md:px-0 flex flex-col justify-center flex-1 md:my-0'
            }
          >
            <div className={'flex md:w-[212%] justify-end'}>
              <div
                className={
                  'md:max-w-[90%] rounded-2xl md:rounded-b-0 p-8 mt-[-15rem] mb-[-2rem] md:my-0 rounded-t-2xl backdrop-blur-lg bg-blue-100 bg-opacity-60'
                }
              >
                <Slide>
                  <h3
                    className={`text-5xl font-bold font-NouvelBold  text-left`}
                  >
                    {t('automation.laboratory.titlePart1')}
                    <br />
                    <span className={'text-primary'}>
                      {t('automation.laboratory.titlePart2')}
                    </span>
                  </h3>
                </Slide>
                <img
                  loading={'lazy'}
                  src={Line}
                  alt={'line'}
                  className={'py-8'}
                />
                <Slide direction={'right'}>
                  <h4
                    className={`text-xl md:text-4xl font-bold font-NouvelBold  text-left uppercase`}
                  >
                    {t('automation.laboratory.subtitle')}
                  </h4>
                </Slide>
                <p className={`px-2 py-8 font-NouvelBold`}>
                  <Zoom>{t('automation.laboratory.long')}</Zoom>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
