import '../../pages/home/style.scss';

import useIsMobileQuery from '../../hooks/use-mobile-query';

interface MainSectionProps {
  title: {
    primary: string;
    secondary: string;
  };
  imageSource: {
    desktop: string;
    mobile: string;
  };
  centerText?: boolean;
  lang?: string;
}

const MainSection: React.FC<MainSectionProps> = (props) => {
  const isMobile = useIsMobileQuery();

  return (
    <div className="relative min-h-screen overflow-hidden">
      <img
        loading={'lazy'}
        alt={'background'}
        src={isMobile ? props.imageSource.mobile : props.imageSource.desktop}
        className="w-full max-w-full object-cover object-center min-h-screen hero-background-image"
      />
      <div
        className={`flex flex-col font-bold absolute left-7 ${props.centerText ? 'top-1/2 -translate-y-1/2' : ' bottom-9'}  md:left-40 md:top-2/3 md:-translate-y-2/3 gap-0 font-[NouvelRRegular] ${props.lang === 'de' ? 'text-4xl' : 'text-5xl'} md:text-6xl md:text-[100px] leading-[50px] md:leading-[80px]`}
      >
        <h3 className={`text-primary  animate__animated animate__slideInUp`}>
          {props.title.primary}
        </h3>
        <h3 className={`text-white  animate__animated animate__slideInDown`}>
          {props.title.secondary}
        </h3>
      </div>
    </div>
  );
};

export default MainSection;
