import { useTranslation } from 'react-i18next';

import assistanceInSettingUpAdministrativeWorkIcon from '/src/assets/images/offshoring/assistance-in-setting-up-administrative-work-icon.svg';
import businessTeamMangerMeetingImage from '/src/assets/images/offshoring/business-team-manager-meeting.webp';
import companyRegistrationIcon from '/src/assets/images/offshoring/company-registration-icon.svg';
import legalAndFinancialConsultingIcon from '/src/assets/images/offshoring/legal-and-financial-consulting-icon.svg';
import otherServicesIcon from '/src/assets/images/offshoring/other-services-icon.svg';

import InfoSection from '../../../components/info-section/info-section';

const OurServicesSection = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <InfoSection
      lang={lang}
      title={{
        primary: t('offshoring.services.titlePart1'),
        secondary: t('offshoring.services.titlePart2'),
      }}
      iconSectionTitle={t('offshoring.services.iconsTitle')}
      icons={[
        {
          label: t('offshoring.services.companyRegistration'),
          soruce: companyRegistrationIcon,
          width: 62,
          height: 66,
        },
        {
          label: t('offshoring.services.legalAndFinancialConsulting'),
          soruce: legalAndFinancialConsultingIcon,
          width: 92,
          height: 67,
        },
        {
          label: t(
            'offshoring.services.assistanceInSettingUpAdministrativeWork',
          ),
          soruce: assistanceInSettingUpAdministrativeWorkIcon,
          width: 50,
          height: 67,
        },
        {
          label: t('offshoring.services.otherServices'),
          soruce: otherServicesIcon,
          width: 73,
          height: 73,
        },
      ]}
      content={{
        text: t('offshoring.services.long'),
        imageSource: businessTeamMangerMeetingImage,
      }}
      subtitle={t('offshoring.services.subtitle')}
    />
  );
};

export default OurServicesSection;
