import '../../pages/home/style.scss';

import { ReactNode } from 'react';

interface HeroProps {
  image: string;
  text: ReactNode;
}
export const Hero = ({ image, text }: HeroProps) => {
  return (
    <div className={'bg-black w-full h-screen hero overflow-hidden relative'}>
      <div
        className="hero-background-image absolute top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div
        className={
          'flex flex-col justify-center my-auto ml-4 md:ml-32 h-full font-NouvelBold'
        }
      >
        {text}
      </div>
    </div>
  );
};
