import 'animate.css';

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowDownFilled, HeroImage } from '../../assets/images/home-page';
import { Hero } from '../../components/hero/Hero.tsx';
import { AboutUs } from './AboutUs.tsx';
import { CustomSolutions } from './CustomSolutions.tsx';
import { GetInTouch } from './GetInTouch.tsx';
import { Services } from './Services.tsx';

export const Home = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleClick = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <Hero
        image={HeroImage}
        text={
          <div className={'relative overflow-hidden'}>
            <h2
              className={
                'first-letter:text-primary text-[3rem] md:text-[4rem] font-bold text-white leading-12 font-NouvelBold animate__animated animate__slideInDown'
              }
            >
              Genuine
            </h2>{' '}
            <h2
              className={
                'first-letter:text-primary text-[3rem] md:text-[4rem] font-bold text-white leading-12 font-NouvelBold animate__animated animate__slideInLeft'
              }
            >
              Innovative
            </h2>{' '}
            <h2
              className={
                'first-letter:text-primary text-[3rem] md:text-[4rem] font-bold text-white leading-12 NouvelBold animate__animated animate__slideInUp'
              }
            >
              Superb
            </h2>
            <p className={'text-[1rem] font-bold text-white'}>
              {t('home.heroLongText')}
            </p>
            <button onClick={handleClick} className={'mt-8'}>
              <img src={ArrowDownFilled} alt={'down'} />
            </button>
          </div>
        }
      />
      <AboutUs aboutRef={aboutRef} />
      <Services />
      <CustomSolutions />
      <GetInTouch />
      {/*<Testimonials />*/}
    </>
  );
};
