import '../home/style.scss';

interface SliderElementProps {
  bgImage: string;
  title: string;
  number: number;
  activeIndex: number;
  text: string;
  onClick: (e: number) => void;
}
export const TileElement = ({
  bgImage,
  number,
  title,
  activeIndex = 0,
  text,
  onClick,
}: SliderElementProps) => {
  return (
    <div
      onClick={() => onClick(number - 1)}
      className={`aspect-[3/4] slider-element md:border-b-8 border-solid box-content ${activeIndex + 1 === number && 'border-primary border-b-8'} text-white ${activeIndex + 1 !== number && 'bg-gray-700'} bg-blend-overlay`}
      style={{
        backgroundImage: `url(${bgImage})`,
        zIndex: 2,
        transition: 'all 1s',
        backgroundSize: activeIndex + 1 === number ? '110%' : '100%',
      }}
    >
      <div className={'flex flex-col justify-between h-full'}>
        <div
          className={
            'w-full pt-8 pr-8 font-bold text-4xl text-right font-shoulders'
          }
        >
          {number}
        </div>
        <div
          style={{
            transition: 'all 1s',
          }}
          className={` overflow-hidden ${activeIndex + 1 === number ? 'background-cutout h-[300px]' : 'h-32'} m-4 box-content`}
        >
          <div
            className={
              'w-full p-8 lg:p-1 xl:p-8 font-bold text-4xl lg:text-2xl xl:text-4xl text-left  h-32 lg:h-14 xl:h-32'
            }
          >
            {title}
          </div>
          <div
            className={`overflow-hidden ${activeIndex + 1 === number ? 'h-[200px]  p-4' : 'h-0'} transition-all`}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};
