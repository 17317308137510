import { Zoom } from 'react-awesome-reveal';

import iso1Icon from '/src/assets/images/quality/iso-1-icon.webp';
import iso2Icon from '/src/assets/images/quality/iso-2-icon.webp';
import iso3Icon from '/src/assets/images/quality/iso-3-icon.webp';
import iso4Icon from '/src/assets/images/quality/iso-4-icon.webp';

import { CertISO27001 } from '../../../assets/images/quality';

const IsoSection = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-9 lg:gap-20 items-center justify-center mb-8  lg:mt-20 lg:mb-40">
      <Zoom cascade duration={400}>
        <img src={iso1Icon} width={196} height={196} alt={'iso1'} />
        <img src={iso2Icon} width={196} height={196} alt={'iso2'} />
        <img src={iso3Icon} width={196} height={196} alt={'iso3'} />
        <img src={iso4Icon} width={196} height={196} alt={'iso4'} />
        <img src={CertISO27001} width={196} height={196} alt={'iso5'} />
      </Zoom>
    </div>
  );
};

export default IsoSection;
