import { RefObject, useEffect, useState } from 'react';

const useObserver = (
  targetRef: RefObject<Element>,
  thresholdPixels: number,
): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const visiblePixels = entry.intersectionRect.height > thresholdPixels;
          setIsVisible(visiblePixels);
        });
      },
      { threshold: [0, 0.5, 1], rootMargin: `-${thresholdPixels}px` },
    ); // Adding threshold and rootMargin

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (observer && observer.disconnect) {
        observer.disconnect();
      }
    };
  }, [targetRef, thresholdPixels]);
  return isVisible;
};

export default useObserver;
