import '@mantine/core/styles.css';
import 'animate.css';

import { createTheme, MantineProvider } from '@mantine/core';

import useIsMobileQuery from '../../hooks/use-mobile-query';
import LocationSection from '../locations/location-section/location-section';
import FormSection from './form-section/form-section';
import LandingSection from './landing-section/landing-section';
import ContactUseLocationSection from './location-section/location-section';

const theme = createTheme({});

export const ContactUs = () => {
  const isMobile = useIsMobileQuery();
  return (
    <>
      <LandingSection />
      <div className="flex flex-col md:flex-row">
        <MantineProvider theme={theme}>
          <FormSection />
        </MantineProvider>
        <ContactUseLocationSection />
      </div>
      <div className="bg-[#EEEEEE] py-20 md:px-14">
        <LocationSection withoutBanner reverse={!isMobile} />
      </div>
    </>
  );
};
