import { useTranslation } from 'react-i18next';

import contactUseLanding from '/src/assets/images/contact-us/contact-us-main.png';
import contactUsLandingMobile from '/src/assets/images/contact-us/landing-mobile.png';

import MainSection from '../../../components/main-section/main-section';

const LandingSection = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <MainSection
      title={{
        primary: t('contactUs.landing.titlePart1'),
        secondary: t('contactUs.landing.titlePart2'),
      }}
      lang={lang}
      centerText
      imageSource={{
        desktop: contactUseLanding,
        mobile: contactUsLandingMobile,
      }}
    />
  );
};

export default LandingSection;
