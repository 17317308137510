import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../home/style.scss';

import { useState } from 'react';
import { AttentionSeeker, Fade, Slide } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

import {
  Bending,
  Laser,
  Turning,
  Welding,
} from '../../assets/images/home-page';
import { ArrowRight, Rectangle } from '../../assets/images/services';
import { TileElement } from './TileElement.tsx';

export const Tiles = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { t } = useTranslation();
  return (
    <div className={'bg-white relative'}>
      <div
        style={{
          zIndex: 0,
          position: 'absolute',
          top: 0,
          right: 0,
          width: '70%',
          height: '30%',
          backgroundImage: `url(${Rectangle})`,
          backgroundSize: 'cover',
        }}
      />

      <div className={'flex relative p-8 md:px-32'}>
        <div className={'flex-col flex md:flex-row  flex-1 py-16 items-start'}>
          <div className={'flex flex-1'}>
            <div className={`overflow-hidden`}>
              <h3
                className={`flex-1 text-5xl md:text-6xl text-primary font-bold font-NouvelBold  text-left max-w-[500px]`}
              >
                <Slide>{t('automation.tiles.titlePart1')}</Slide>
              </h3>
              <h3
                className={`flex-1 text-5xl md:text-6xl font-bold font-NouvelBold  text-left max-w-[500px]`}
              >
                <Fade direction={'up'}>{t('automation.tiles.titlePart2')}</Fade>
              </h3>
            </div>
            <AttentionSeeker effect={'jello'}>
              <img
                loading={'lazy'}
                src={ArrowRight}
                alt={'right'}
                className={'hidden md:block'}
              />
            </AttentionSeeker>
          </div>
          <p className={`flex-1 p-2 font-NouvelBold`}>
            <Fade>{t('automation.tiles.long')}</Fade>
          </p>
        </div>
      </div>
      <div
        style={{ zIndex: 2 }}
        className={
          'z-10 p-8 md:px-32  lg:px-4 xl:px-32 pt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:gap-y-4 box-border bg-bottom bg-contain bg-no-repeat services-background-image'
        }
      >
        <Fade cascade duration={300}>
          <TileElement
            bgImage={Laser}
            title={t('automation.tiles.hplcSystems')}
            number={1}
            activeIndex={activeSlide}
            text={t('automation.tiles.hplcSystemsText')}
            onClick={(e) => setActiveSlide(e)}
          />
          <TileElement
            bgImage={Bending}
            title={t('automation.tiles.incubators')}
            number={2}
            activeIndex={activeSlide}
            text={t('automation.tiles.incubatorsText')}
            onClick={(e) => setActiveSlide(e)}
          />
          <TileElement
            bgImage={Welding}
            title={t('automation.tiles.plateHotels')}
            number={3}
            activeIndex={activeSlide}
            text={t('automation.tiles.plateHotelsText')}
            onClick={(e) => setActiveSlide(e)}
          />
          <TileElement
            bgImage={Turning}
            title={t('automation.tiles.pippetingRobots')}
            number={4}
            activeIndex={activeSlide}
            text={t('automation.tiles.pippetingRobotsText')}
            onClick={(e) => setActiveSlide(e)}
          />
        </Fade>
      </div>
    </div>
  );
};
