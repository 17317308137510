import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Strip } from '../../assets/images/home-page';
import { MainRoutes } from '../../model/enums/routes/MainRoutes.ts';

export const GetInTouch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const array = Array.from({ length: 9 }, (_e, index) => (
    <h3
      key={index}
      className={
        'uppercase py-16 px-3 text-3xl font-extrabold text-primary font-NouvelBold'
      }
    >
      {t('home.getInTouch.titlePart1')}
      <span className={'text-white'}>{t('home.getInTouch.titlePart2')}</span>
    </h3>
  ));

  return (
    <div
      style={{ backgroundImage: `url(${Strip})` }}
      className={'bg-cover bg-gray-700 bg-blend-overlay'}
      onClick={() => navigate(`../${MainRoutes.CONTACT}`)}
    >
      <Marquee>{array}</Marquee>
    </div>
  );
};
