import { useTranslation } from 'react-i18next';

import businessExpertiseIcon from '/src/assets/images/offshoring/business-expertise-icon.svg';
import businessTeamManagerMeeting from '/src/assets/images/offshoring/business-team-manager-meeting-2.webp';
import financialServicesIcon from '/src/assets/images/offshoring/financial-services-icon.svg';
import ITServicesIcon from '/src/assets/images/offshoring/IT-services-icon.svg';
import manufacturingServicesIcon from '/src/assets/images/offshoring/manufacturing-services-icon.svg';

import InfoSection from '../../../components/info-section/info-section';

const ExpertiseAndSupportSection = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <InfoSection
      lang={lang}
      title={{
        primary: t('offshoring.expertise.titlePart1'),
        secondary: t('offshoring.expertise.titlePart2'),
      }}
      iconSectionTitle={t('offshoring.expertise.iconsTitle')}
      icons={[
        {
          label: t('offshoring.expertise.itServices'),
          soruce: ITServicesIcon,
          width: 80,
          height: 60,
        },
        {
          label: t('offshoring.expertise.financialServices'),
          soruce: financialServicesIcon,
          width: 66,
          height: 69,
        },
        {
          label: t('offshoring.expertise.manufacturingServices'),
          soruce: manufacturingServicesIcon,
          width: 65,
          height: 65,
        },
        {
          label: t('offshoring.expertise.businessExpertise'),
          soruce: businessExpertiseIcon,
          width: 73,
          height: 55,
        },
      ]}
      content={{
        text: t('offshoring.expertise.long'),
        imageSource: businessTeamManagerMeeting,
      }}
      subtitle={t('offshoring.expertise.subtitle')}
      isReversed
    />
  );
};

export default ExpertiseAndSupportSection;
