import { useTranslation } from 'react-i18next';

import locationsLandingMobile from '/src/assets/images/locations/location-landing-mobile.webp';
import locationsLanding from '/src/assets/images/locations/locations-main.webp';

import MainSection from '../../../components/main-section/main-section';

const LandingSection = () => {
  const { t } = useTranslation();
  return (
    <MainSection
      imageSource={{
        desktop: locationsLanding,
        mobile: locationsLandingMobile,
      }}
      title={{
        primary: t('locations.landing.titlePart1'),
        secondary: t('locations.landing.titlePart2'),
      }}
      centerText
    />
  );
};

export default LandingSection;
