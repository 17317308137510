import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useContactUsSchema = () => {
  const { t } = useTranslation();
  const contactUsSchema = useMemo(
    () =>
      z.object({
        name: z.string().min(1, t('contactUs.form.required')),
        phone: z.string().min(1, t('contactUs.form.required')),
        email: z.string().email({ message: t('contactUs.form.invalidEmail') }),
        subject: z.string().min(1, t('contactUs.form.required')),
        message: z.string().min(1, t('contactUs.form.required')),
      }),
    [t],
  );

  return contactUsSchema;
};
export type ContactUsSchemaType = z.infer<
  ReturnType<typeof useContactUsSchema>
>;
export default useContactUsSchema;
