import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

import { useRef, useState } from 'react';
import { Slide } from 'react-awesome-reveal';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import {
  ArrowRightFilled,
  Assembly,
  Bending,
  BottomRightArrow,
  CableAssembly,
  Laser,
  Logistics,
  Painting,
  Turning,
  Welding,
} from '../../assets/images/home-page';
import { MainRoutes } from '../../model/enums/routes/MainRoutes.ts';
import { SliderElement } from './SliderElement.tsx';

export const Services = () => {
  const navigate = useNavigate();
  const sliderRef = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState(0); // State to hold the active slide index
  const settings = {
    dots: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoPlay: true,
    nextArrow: <img src={ArrowRightFilled} alt={'next'} />,
    prevArrow: (
      <img
        src={ArrowRightFilled}
        alt={'prev'}
        style={{ display: `${activeSlide === 0 ? 'none' : 'block'}` }}
      />
    ),
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next); // Update active slide index after slide change
      return { current, next };
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const setSlide = (index: number) => {
    setActiveSlide(index);
    sliderRef.current?.slickGoTo(index);
  };
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div
      className={
        'bg-gray-100 p-8 md:p-32 bg-contain bg-bottom bg-no-repeat services-background-image'
      }
    >
      <div className={'flex relative'}>
        <div className={'flex-1'}>
          <Slide direction={'up'}>
            <h3
              className={`${lang === 'de' ? 'text-3xl' : 'text-6xl'} lg:text-6xl font-bold font-NouvelBold  text-center`}
            >
              {t('home.services.titleSecondary')}{' '}
              <span className={'text-primary'}>
                {t('home.services.titlePrimary')}
              </span>
            </h3>
          </Slide>
          <Slide cascade direction={'right'}>
            <span
              className={`font-bold ${lang === 'de' ? 'text-xl' : 'text-2xl'} md:text-2xl py-6`}
            >
              {t('home.services.subtitle')}
            </span>
          </Slide>
          <Slide direction={'down'}>
            <p className={`p-2 font-NouvelBold`}>
              {<Trans i18nKey={'home.services.long'} />}
            </p>
          </Slide>
        </div>
        <div
          className={
            'absolute flex-1 h-32 md:relative md:flex justify-end md:h-64 object-right-top bottom-0 right-0'
          }
        >
          <img
            className={'h-32 w-32 md:h-64 md:w-64 object-right-top'}
            src={BottomRightArrow}
            alt={'look bellow'}
          />
        </div>
      </div>
      <div>
        <Slider {...settings} className={'slider'} ref={sliderRef}>
          <SliderElement
            bgImage={Laser}
            title={t('home.services.laserAndWatercutting')}
            number={1}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={Bending}
            title={t('home.services.bending')}
            number={2}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={Welding}
            title={t('home.services.welding')}
            number={3}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={Turning}
            title={t('home.services.turningAndMilling')}
            number={4}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={Painting}
            title={t('home.services.painting')}
            number={5}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={Assembly}
            title={t('home.services.assembly')}
            number={6}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={CableAssembly}
            title={t('home.services.cableAssembly')}
            number={7}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
          <SliderElement
            bgImage={Logistics}
            title={t('home.services.logistics')}
            number={8}
            activeIndex={activeSlide}
            onClick={setSlide}
          />
        </Slider>
      </div>
      <button
        className={
          'hidden mx-auto uppercase md:flex gap-4 items-center text-2xl font-NouvelBold md:pt-[10%] box-content'
        }
        onClick={() => navigate(MainRoutes.SERVICES)}
      >
        {t('home.services.findAll')}
        <img src={ArrowRightFilled} alt={'find out more'} className={'h-16'} />
      </button>
    </div>
  );
};
