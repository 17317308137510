import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Logo } from '../../assets/images/services';
import useObserver from '../../hooks/useObserver.ts';

export const Message = () => {
  const refH1 = useRef<HTMLHeadingElement>(null);
  const refP = useRef<HTMLParagraphElement>(null);
  const isVisibleH1 = useObserver(refH1, 0);
  const isVisibleP = useObserver(refP, 0);
  const { t } = useTranslation();
  return (
    <div
      className={'flex relative p-8 lg:px-32 bg-center bg-contain bg-no-repeat'}
      style={{
        backgroundImage: `url(${Logo})`,
        backgroundSize: '10%',
      }}
    >
      <div className={'flex-col flex lg:flex-row  flex-1 py-16 items-start'}>
        <p
          className={`flex-1 p-2 font-NouvelBold animate__animated ${isVisibleP ? 'animate__slideInRight' : ''}`}
          ref={refP}
        >
          {t('services.message.long')}
        </p>
        <div className={'flex flex-1 justify-end'}>
          <h3
            ref={refH1}
            className={`flex-1 text-4xl lg:text-5xl font-bold font-NouvelBold  lg:text-right text-left max-w-[500px] uppercase animate__animated ${isVisibleH1 ? 'animate__slideInLeft' : ''}`}
          >
            {t('services.message.titlePart1')}
            <span className={'text-primary'}>
              {' '}
              {t('services.message.titlePart2')}
            </span>{' '}
            {t('services.message.titlePart3')}
            <span className={'text-primary'}>
              {' '}
              {t('services.message.titlePart4')}
            </span>{' '}
            {t('services.message.titlePart5')}
            <span className={'text-primary'}>
              {t('services.message.titlePart6')}
            </span>
          </h3>
        </div>
        <img src={Logo} alt={'logo'} className={'lg:hidden mt-16'} />
      </div>
    </div>
  );
};
