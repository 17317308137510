import { useCallback, useEffect, useState } from 'react';

import upButton from '/src/assets/images/home-page/back-to-top.svg';

import useIsMobileQuery from '../../hooks/use-mobile-query';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useIsMobileQuery();

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    setIsVisible(scrollPosition > 300);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (!isVisible) {
    return null;
  }

  return (
    <img
      loading={'lazy'}
      alt={'up'}
      src={upButton}
      onClick={handleScrollToTop}
      className="fixed bottom-20 right-6"
      width={isMobile ? 80 : 100}
      height={isMobile ? 80 : 100}
    />
  );
};

export default BackToTop;
