import { TextInput } from '@mantine/core';
import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';

import { ContactUsSchemaType } from './schema';

interface ControlledInputProps {
  control: Control<ContactUsSchemaType>;
  name: Path<ContactUsSchemaType>;
  placeholder: string;
}

const ControlledInput: React.FC<ControlledInputProps> = (props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <TextInput
          {...field}
          error={fieldState.error?.message}
          size="lg"
          placeholder={props.placeholder}
        />
      )}
    />
  );
};

export default ControlledInput;
