import BusinessPolicySection from './business-policy-section/business-policy-section.tsx';
import IsoSection from './iso-section/iso-section.tsx';
import LandingSection from './landing-section/landing-section.tsx';
import { MissionAndVision } from './MisionVision/MissionAndVision.tsx';
import OurCertificatesSection from './our-certificates-section/our-certificates-section.tsx';

export const Quality = () => {
  return (
    <>
      <LandingSection />
      <MissionAndVision />
      <OurCertificatesSection />
      <IsoSection />
      <BusinessPolicySection />
    </>
  );
};
