import { Fade, Slide } from 'react-awesome-reveal';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ArrowRightFilled,
  CustomSolutionsImage,
} from '../../assets/images/home-page';
import { MainRoutes } from '../../model/enums/routes/MainRoutes.ts';

export const CustomSolutions = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div
      className={`flex flex-col-reverse lg:flex-row  bg-center sm:bg-[50rem_20rem] md:bg-[30rem_center] bg-contain bg-no-repeat`}
    >
      <img
        src={CustomSolutionsImage}
        alt={'about-us'}
        className={'flex-1 lg:w-1/2'}
      />
      <div className={'flex-1 my-auto py-8 md:pl-32'}>
        <div className={'md:max-w-[70%] overflow-hidden'}>
          <Slide>
            <h3
              className={`${lang === 'de' ? 'text-3xl' : 'text-6xl'} lg:text-6xl font-bold font-NouvelBold  text-center`}
            >
              {t('home.customSolutions.titleSecondary')}{' '}
              <span className={'text-primary'}>
                {t('home.customSolutions.titlePrimary')}
              </span>
            </h3>
          </Slide>
          <Fade>
            <p className={`p-2 font-NouvelBold`}>
              <Trans i18nKey={'home.customSolutions.long'} />
            </p>
          </Fade>
          <button
            className={
              'hidden uppercase md:flex gap-4 items-center text-2xl font-NouvelBold md:p-[10%]'
            }
            onClick={() => navigate(MainRoutes.OFFSHORING)}
          >
            {t('home.customSolutions.more')}
            <img
              src={ArrowRightFilled}
              alt={'find out more'}
              className={'h-16'}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
