import { AttentionSeeker, Fade, Slide } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

import {
  Advice,
  ArrowDownRight,
  BackOfficeIcon,
  CustomizedIcon,
  CustomMadeIcon,
  Equipment,
  Inhouse,
  LaboratoryIcon,
  OnsiteIcon,
} from '../../assets/images/automation';

export const Services = () => {
  const { t } = useTranslation();
  return (
    <div className={'bg-black'}>
      <div className={'flex flex-col md:flex-row'}>
        <div
          className={
            'flex flex-1 bg-primary md:ml-[5%] md:max-w-[45%] p-8 w-full justify-between items-end rounded-b-2xl'
          }
        >
          <div>
            <Slide cascade>
              <h3
                className={
                  'text-5xl font-bold font-NouvelBold  text-left leading-8'
                }
              >
                {t('automation.services.titlePart1')}
              </h3>
              <h3
                className={
                  'text-5xl font-bold font-NouvelBold  text-left text-white'
                }
              >
                {t('automation.services.titlePart2')}
              </h3>
            </Slide>
          </div>
          <AttentionSeeker effect={'wobble'}>
            <img
              loading={'lazy'}
              src={ArrowDownRight}
              className={'h-16 rotate-90 md:rotate-0'}
              alt={'right'}
            />
          </AttentionSeeker>
        </div>
        <div className={'flex-1'}></div>
      </div>
      <div className={'grid sm:grid-cols-2 md:grid-cols-4 p-[5%] gap-16'}>
        <Fade cascade duration={300}>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={LaboratoryIcon}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.laboratoryService')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={OnsiteIcon}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.onSiteService')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={Inhouse}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.inHouseServices')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={Advice}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.advice')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={CustomMadeIcon}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.customMadeProducts')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={BackOfficeIcon}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.backOffice')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={Equipment}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.laboratoryEquipmentMaintenance')}
            </p>
          </div>
          <div className={'flex flex-col justify-center gap-6'}>
            <img
              loading={'lazy'}
              src={CustomizedIcon}
              alt={'lab'}
              className={'h-24 w-24 m-auto md:m-0'}
            />
            <p className={'text-white text-xl text-center  md:text-left'}>
              {t('automation.services.customizedSolutions')}
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};
