import { Fade, Slide } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

export const MissionAndVision = () => {
  const { t } = useTranslation();
  return (
    <div className={'flex p-4 xl:p-24 gap-6 flex-col lg:flex-row'}>
      <div>
        <Slide className="font-bold text-4xl md:text-6xl md:text-[64px] flex flex-col leading-10 mb-8 ">
          {t('quality.mission')}
        </Slide>
        <Fade duration={2000} className={'text-lg md:text-xl mb-10 md:mb-40'}>
          {t('quality.missionText')}
        </Fade>
      </div>
      <div>
        <Slide
          direction={'right'}
          className="font-bold text-4xl md:text-6xl md:text-[64px] flex flex-col leading-[55px] text-primary mb-8"
        >
          {t('quality.vision')}
        </Slide>
        <Fade duration={2000} className={'text-lg md:text-xl mb-10 md:mb-40'}>
          {t('quality.visionText')}
        </Fade>
      </div>
    </div>
  );
};
