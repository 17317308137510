import montenegroIcon from '/src/assets/images/locations/montenegro-icon.svg';
import serbiaIcon from '/src/assets/images/locations/serbia-icon.webp';
import switzerlandIcon from '/src/assets/images/locations/switzerland-icon.svg';

import { Map } from '../../../assets/images/locations';
import LocationItem from './location-item';

interface LocationSectionProps {
  withoutBanner?: boolean;
  reverse?: boolean;
}

const LocationSection: React.FC<LocationSectionProps> = (props) => {
  return (
    <div
      className={
        props.withoutBanner
          ? ''
          : 'flex flex-col gap-20 font-[NouvelRRegular] relative md:aspect-[1920/1143] md:z-0 md:justify-center'
      }
    >
      {!props.withoutBanner && (
        <img
          src={Map}
          className="w-full md:absolute top-0 left-0 object-cover h-full"
          alt={'locations'}
        />
      )}
      <div
        style={{
          clipPath: 'polygon(90% 0, 100% 10%, 100% 100%, 0 100%, 0 0)',
        }}
        className={
          props.reverse
            ? 'flex justify-around'
            : 'flex flex-col gap-11 mb-16 md:z-10 md:bg-[#ffffff]/50 md:w-full md:max-w-[600px]  bg-clip-border md:backdrop-blur-lg md:my-auto md:ml-[100px]'
        }
      >
        <LocationItem
          countryName="MONTENEGRO"
          type="HEADQUARTERS"
          address={
            <>
              <div>Global Industrial Solutions d.o.o.</div>
              <div>Industrijska bb</div>
              <div>84000 Bijelo Polje</div>
              <div>Montenegro</div>
            </>
          }
          phoneNumber="+382 (0)50 683 555"
          iconSource={montenegroIcon}
        />
        <LocationItem
          countryName="SERBIA"
          type="PRODUCTION"
          address={
            <>
              <div>Global Industrial Solutions d.o.o.</div>
              <div>Kralja Aleksandra I 4</div>
              <div>11400 Rajkovac</div>
              <div>Serbia</div>
            </>
          }
          phoneNumber="+381 (0)69 279 71 59"
          iconSource={serbiaIcon}
        />
        <LocationItem
          countryName="SWITZERLAND"
          type="LOGISTICS"
          address={
            <>
              <div>MonteTec GmbH</div>
              <div>Buhofstrasse 60</div>
              <div>9424 Rheineck</div>
              <div>Switzerland</div>
            </>
          }
          phoneNumber="+41 (0)71 888 00 20"
          iconSource={switzerlandIcon}
        />
      </div>
    </div>
  );
};

export default LocationSection;
