import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AboutUsImage, ArrowRightFilled } from '../../assets/images/home-page';
import useObserver from '../../hooks/useObserver.ts';
import { MainRoutes } from '../../model/enums/routes/MainRoutes.ts';

interface AboutUsProps {
  aboutRef: RefObject<HTMLDivElement>;
}
export const AboutUs = ({ aboutRef }: AboutUsProps) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLParagraphElement>(null);
  const isVisible = useObserver(aboutRef, 0);
  const isPVisible = useObserver(ref, 0);
  const { t } = useTranslation();
  return (
    <div
      ref={aboutRef}
      className={
        'flex flex-col lg:flex-row  bg-center sm:[50rem_20rem] md:bg-[30rem_center] bg-contain bg-no-repeat'
      }
      style={{
        backgroundImage: 'url("src/assets/images/home-page/nodes.webp")',
      }}
    >
      <div className={'flex-1 my-auto py-8 md:pl-32'}>
        <div className={'md:max-w-[70%] overflow-hidden'}>
          <h3
            className={`text-6xl font-bold font-NouvelBold  text-center animate__animated ${isVisible ? 'animate__slideInDown block' : ''}`}
          >
            {t('home.about.titleSecondary')}{' '}
            <span
              className={'text-primary animate__animated animate__slideInUp'}
            >
              {t('home.about.titlePrimary')}
            </span>
          </h3>
          <p
            className={`p-2 font-NouvelBold animate__animated ${isPVisible ? 'animate__slideInRight block' : ''}`}
            ref={ref}
          >
            {t('home.about.long')}
          </p>
          <button
            className={
              'hidden uppercase lg:flex gap-4 items-center text-2xl font-NouvelBold lg:p-[10%]'
            }
            onClick={() => navigate(MainRoutes.QUALITY)}
          >
            {t('home.about.more')}
            <img
              loading={'lazy'}
              src={ArrowRightFilled}
              alt={'find out more'}
              className={'h-16'}
            />
          </button>
        </div>
      </div>
      <img src={AboutUsImage} alt={'about-us'} className={'flex-1 lg:w-1/2'} />
    </div>
  );
};
