import { useTranslation } from 'react-i18next';

import qualityLandingMobile from '/src/assets/images/quality/quality-landing-mobile.webp';
import qualityLadning from '/src/assets/images/quality/quality-main.png';

import MainSection from '../../../components/main-section/main-section';

const LandingSection = () => {
  const { t } = useTranslation();
  return (
    <MainSection
      title={{
        primary: t('quality.landing.titlePart1'),
        secondary: t('quality.landing.titlePart2'),
      }}
      imageSource={{
        desktop: qualityLadning,
        mobile: qualityLandingMobile,
      }}
      centerText
    />
  );
};

export default LandingSection;
