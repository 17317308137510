import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './gallery.scss';

import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { ArrowRightFilled } from '../../assets/images/home-page';
import {
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  P8,
  P9,
  P10,
  P11,
  P12,
  P13,
  P14,
  P15,
  P16,
  P17,
} from '../../assets/images/services/factory';
import {
  HQ1,
  HQ3,
  HQ4,
  HQ5,
  HQ6,
  HQ7,
  HQ8,
  HQ9,
  HQ10,
  HQ11,
  HQ12,
  HQ13,
  HQ14,
  HQ15,
  HQ16,
  HQ17,
  HQ18,
  HQ19,
  HQ20,
} from '../../assets/images/services/headquarters';

export const Gallery = () => {
  const { t } = useTranslation();
  const hqImages = [
    HQ15,
    HQ1,
    HQ3,
    HQ4,
    HQ5,
    HQ6,
    HQ7,
    HQ8,
    HQ9,
    HQ10,
    HQ11,
    HQ12,
    HQ13,
    HQ14,
    HQ15,
    HQ16,
    HQ17,
    HQ18,
    HQ19,
    HQ20,
  ];
  const pImages = [
    P1,
    P2,
    P3,
    P4,
    P5,
    P6,
    P7,
    P8,
    P9,
    P10,
    P11,
    P12,
    P13,
    P14,
    P15,
    P16,
    P17,
  ];

  const settings = {
    customPaging: function (i: number) {
      return (
        <a>
          <img src={hqImages[i]} alt={hqImages[i]} height={'48px'} />
        </a>
      );
    },
    nextArrow: (
      <img src={ArrowRightFilled} alt={'next'} className={'right-1/2'} />
    ),
    prevArrow: (
      <img src={ArrowRightFilled} alt={'next'} className={'right-1/2'} />
    ),
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    adaptiveHeight: true,
  };
  const pSettings = {
    customPaging: function (i: number) {
      return (
        <a>
          <img src={pImages[i]} alt={pImages[i]} height={'48px'} />
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    adaptiveHeight: true,
    nextArrow: (
      <img src={ArrowRightFilled} alt={'next'} className={'right-1/2'} />
    ),
    prevArrow: (
      <img src={ArrowRightFilled} alt={'next'} className={'right-1/2'} />
    ),
  };
  const hqSlides = hqImages.map((image) => (
    <div className={'relative max-h-full'} key={image}>
      <img
        src={image}
        alt={image}
        className={'object-contain object-center mx-auto'}
      />
    </div>
  ));
  const pSlides = pImages.map((image) => (
    <div className={'relative max-h-full'} key={image}>
      <img
        src={image}
        alt={image}
        className={'object-contain object-center mx-auto h-2/3'}
      />
    </div>
  ));

  return (
    <div className={'flex gap-6 flex-col lg:flex-row px-4'}>
      <div className={'overflow-hidden flex-1'}>
        <h3
          className={`text-3xl lg:text-6xl font-bold font-NouvelBold  text-center my-6`}
        >
          {t('services.gallery.montenegro')}
        </h3>
        <Slider
          {...settings}
          className={'slider bg-black  mx-auto relative gallery-slider'}
        >
          {hqSlides}
        </Slider>
      </div>
      <div className={'overflow-hidden flex-1'}>
        <h3
          className={`text-3xl text-primary lg:text-6xl font-bold font-NouvelBold  text-center my-6`}
        >
          {t('services.gallery.serbia')}
        </h3>
        <Slider
          {...pSettings}
          className={'slider bg-black mx-auto relative gallery-slider'}
        >
          {pSlides}
        </Slider>
      </div>
    </div>
  );
};
