import { useCallback, useEffect, useState } from 'react';

export default function useIsMobileQuery() {
  const [isMobile, setIsMobile] = useState(false);

  const handleMobileMediaChange = useCallback((event: MediaQueryListEvent) => {
    if (event.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    const matchMedia = window.matchMedia('(max-width: 768px)');
    setIsMobile(matchMedia.matches);
    matchMedia.addEventListener('change', handleMobileMediaChange);

    return () => {
      matchMedia.removeEventListener('change', handleMobileMediaChange);
    };
  }, [handleMobileMediaChange]);

  return isMobile;
}
