interface LocationItemProps {
  iconSource: string;
  countryName: string;
  type: string;
  address: JSX.Element;
  phoneNumber: string;
}

const LocationItem: React.FC<LocationItemProps> = (props) => {
  return (
    <div className="flex flex-col gap-1 items-center">
      <img src={props.iconSource} width={70} height={40} />
      <span className="font-extrabold text-4xl">{props.countryName}</span>
      <span className="text-[21px] text-primary">{props.type}</span>
      <span className="text-[21px] text-center">{props.address}</span>
      <span className="text-[21px]">{props.phoneNumber}</span>
    </div>
  );
};

export default LocationItem;
