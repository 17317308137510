import { createBrowserRouter } from 'react-router-dom';

import { Page } from '../layout/Page.tsx';
import { MainRoutes } from '../model/enums/routes/MainRoutes.ts';
import {
  Automation,
  ContactUs,
  Home,
  Locations,
  Offshoring,
  Quality,
  Services,
} from '../pages';
import WorkInProgress from '../pages/WIP/WorkInProgress.tsx';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Page />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: MainRoutes.AUTOMATION,
        element: <Automation />,
      },
      {
        path: MainRoutes.CONTACT,
        element: <ContactUs />,
      },
      {
        path: MainRoutes.LOCATIONS,
        element: <Locations />,
      },
      {
        path: MainRoutes.OFFSHORING,
        element: <Offshoring />,
      },
      {
        path: MainRoutes.QUALITY,
        element: <Quality />,
      },
      {
        path: MainRoutes.SERVICES,
        element: <Services />,
      },
      {
        path: MainRoutes.CAREER,
        element: <WorkInProgress />,
      },
    ],
  },
]);
