import '../home/style.scss';

interface SliderElementProps {
  bgImage: string;
  title: string;
  number: number;
  activeIndex: number;
  text: string;
  onClick: (e: number) => void;
  lang?: string;
}
export const TileElement = ({
  bgImage,
  number,
  title,
  activeIndex = 0,
  text,
  onClick,
}: SliderElementProps) => {
  return (
    <div
      onClick={() => onClick(number - 1)}
      className={`aspect-[3/4] slider-element md:border-b-8 border-solid box-content ${activeIndex + 1 === number && 'border-primary border-b-8'} text-white ${activeIndex + 1 !== number && 'bg-gray-700'} bg-blend-overlay`}
      style={{
        backgroundImage: `url(${bgImage})`,
        zIndex: 2,
        transition: 'all 1s',
        backgroundSize: activeIndex + 1 === number ? '110%' : '100%',
      }}
    >
      <div className={'flex flex-col justify-between h-full'}>
        <div
          className={
            'w-full pt-1 lg:pt-4 pr-1 lg:pr-4 font-bold text-4xl text-right font-shoulders'
          }
        >
          {number}
        </div>
        <div
          style={{
            transition: 'all 1s',
          }}
          className={`overflow-hidden ${activeIndex + 1 === number ? 'background-cutout h-[400px]' : 'h-48'} md:m-4 box-content`}
        >
          <div
            className={`w-full p-2 font-bold text-2xl xl:text-xl  text-left  xl:p-2 2xl:p-8 h-auto xl:h-14`}
          >
            {title}
          </div>
          <div
            className={`overflow-hidden ${activeIndex + 1 === number ? 'max-h-[300px]  p-1 2xl:p-4' : 'h-0'} transition-all text-sm xl:text-base`}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};
