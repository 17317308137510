import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../home/style.scss';

import { useState } from 'react';
import { AttentionSeeker, Fade, Slide } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

import {
  Assembly,
  Bending,
  CableAssembly,
  Laser,
  Logistics,
  Painting,
  Turning,
  Welding,
} from '../../assets/images/home-page';
import { ArrowRight, Rectangle } from '../../assets/images/services';
import { TileElement } from './TileElement.tsx';

export const Tiles = () => {
  const [activeSlide, setActiveSlide] = useState(0); // State to hold the active slide index
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div className={'bg-white relative'}>
      <div
        style={{
          zIndex: 0,
          position: 'absolute',
          top: 0,
          right: 0,
          width: '70%',
          height: '30%',
          backgroundImage: `url(${Rectangle})`,
          backgroundSize: 'cover',
        }}
      />

      <div className={'flex relative p-8 md:px-32'}>
        <div className={'flex-col flex md:flex-row  flex-1 py-16 items-start'}>
          <div className={'flex flex-1'}>
            <Slide>
              <h3
                className={`flex-1 ${lang === 'de' ? 'text-2xl' : 'text-6xl'} lg:text-6xl text-wrap text-primary font-bold font-NouvelBold  text-left max-w-[500px]`}
              >
                {t('services.tiles.titlePart1')}
                <br />
                <span className={'text-black'}>
                  {t('services.tiles.titlePart2')}
                </span>
              </h3>
            </Slide>
            <AttentionSeeker effect={'heartBeat'}>
              <img
                src={ArrowRight}
                alt={'right'}
                className={'hidden md:block'}
              />
            </AttentionSeeker>
          </div>
          <Fade
            cascade
            style={{ whiteSpace: 'wrap' }}
            className={`flex-1 p-2 font-NouvelBold`}
          >
            <p className={`flex-1 p-2 font-NouvelBold`}>
              {t('services.tiles.long')}
            </p>
          </Fade>
        </div>
      </div>
      <div
        style={{ zIndex: 2 }}
        className={
          'z-10 p-8 lg:px-4 xl:px-32 pt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:gap-y-4 box-border bg-bottom bg-contain bg-no-repeat services-background-image'
        }
      >
        <Fade cascade duration={200}>
          <TileElement
            bgImage={Laser}
            title={t('services.tiles.laserAndWatercutting')}
            number={1}
            activeIndex={activeSlide}
            text={t('services.tiles.laserAndWatercuttingText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={Bending}
            title={t('services.tiles.bending')}
            number={2}
            activeIndex={activeSlide}
            text={t('services.tiles.bendingText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={Welding}
            title={t('services.tiles.welding')}
            number={3}
            activeIndex={activeSlide}
            text={t('services.tiles.weldingText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={Turning}
            title={t('services.tiles.turningAndMilling')}
            number={4}
            activeIndex={activeSlide}
            text={t('services.tiles.turningAndMillingText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={Painting}
            title={t('services.tiles.painting')}
            number={5}
            activeIndex={activeSlide}
            text={t('services.tiles.paintingText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={Assembly}
            title={t('services.tiles.assembly')}
            number={6}
            activeIndex={activeSlide}
            text={t('services.tiles.assemblyText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={CableAssembly}
            title={t('services.tiles.cableAssembly')}
            number={7}
            activeIndex={activeSlide}
            text={t('services.tiles.cableAssemblyText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
          <TileElement
            bgImage={Logistics}
            title={t('services.tiles.logistics')}
            number={8}
            activeIndex={activeSlide}
            text={t('services.tiles.logisticsText')}
            onClick={(e) => setActiveSlide(e)}
            lang={lang}
          />
        </Fade>
      </div>
    </div>
  );
};
