import { GetInTouch } from '../home/GetInTouch';
import LandingSection from './landing-section/landing-section';
import LocationSection from './location-section/location-section';
import OperateSection from './operate-section/operate-section';

export const Locations = () => {
  return (
    <>
      <LandingSection />
      <OperateSection />
      <LocationSection />
      <GetInTouch />
    </>
  );
};
