import '../home/style.scss';
import 'animate.css';

import { Slide } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';

import { ArrowTopRight, Line, RedBg } from '../../assets/images/services';
import { HQ5 } from '../../assets/images/services/headquarters';

export const CustomSolutions = () => {
  const { t } = useTranslation();
  return (
    <div
      className={'flex flex-col lg:flex-row relative'}
      style={{ background: `url(${RedBg})` }}
    >
      <div className={'flex flex-col items-end flex-1'}>
        <img
          src={HQ5}
          alt={'custom solutions'}
          className={'aspect-[4/3] object-cover object-bottom'}
        />
        <img
          src={ArrowTopRight}
          alt={'custom solutions'}
          className={'hidden lg:block pt-8 pr-8 h-48'}
        />
      </div>
      <div
        className={
          'p-4 lg:px-0 flex flex-col justify-center flex-1 lg:my-32 mb-32'
        }
      >
        <div>
          <div
            className={
              'md:max-w-[70%] clip-background p-8 my-auto overflow-hidden'
            }
          >
            <Slide>
              <h3
                className={`text-2xl lg:text-6xl font-bold font-NouvelBold  text-left`}
              >
                {t('services.customSolutions.titlePart1')}
                <br />
                <span className={'text-primary'}>
                  {' '}
                  {t('services.customSolutions.titlePart2')}
                </span>
              </h3>
            </Slide>
            <img src={Line} alt={'line'} className={'py-8'} />
            <Slide cascade duration={300} direction={'right'}>
              <p className={`px-2 py-8`}>{t('services.customSolutions.p1')}</p>
              <p className={`px-2 py-8`}>{t('services.customSolutions.p2')}</p>
              <p className={`px-2 py-8`}>{t('services.customSolutions.p3')}</p>
            </Slide>
          </div>
        </div>
      </div>
      <img
        src={ArrowTopRight}
        alt={'custom solutions'}
        className={'absolute bottom-10 z-10 md:hidden pt-8 pr-8 h-48  mx-auto'}
        style={{ transform: 'translateX(50%)' }}
      />
    </div>
  );
};
