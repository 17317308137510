import { useTranslation } from 'react-i18next';

import countriesIcon from '/src/assets/images/locations/3-countries-icon.svg';

const OperateSection = () => {
  const { t } = useTranslation();

  return (
    <div className="font-[NouvelRRegular] flex flex-col lg:flex-row items-center justify-around my-9 gap-9 lg:my-28">
      <span className="font-bold text-[40px] lg:text-[85px] flex-1 text-center">
        {t('locations.operate.titlePart1')}
      </span>
      <img
        src={countriesIcon}
        className="flex-1 w-1/3 max-w-[350px] max-h-[475px]"
      />
      <div className="flex-1 flex flex-col justify-center">
        <div className="m-auto max-w-[450px] flex flex-col items-center md:items-stretch">
          <span className="font-bold text-[40px] md:text-[85px]">
            {t('locations.operate.titlePart2')}
          </span>
          <div className="  w-[150px] h-[2px] bg-primary mt-8 mb-14" />
          <span className="text-[21px] font-bold md:font-normal md:text-xl text-center md:text-left">
            {t('locations.operate.long')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OperateSection;
